.title-margin
    margin-bottom: 1.5rem


.title.is-1,
.title.is-4
    font-family: $family-title
    font-weight: 700

.title.is-2,
.title.is-3,
.title.is-5
    font-family: $family-primary
    font-weight: 500

.title.is-6
    font-family: $family-primary
    font-weight: 400
