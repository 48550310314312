.simple-search-container
    @extend %page-padding
    padding-top: 2rem
    padding-bottom: 3rem
    background: $isaaffik-darkblue
    color: $white

    .title, .subtitle, strong
        color: inherit

    .radio, .checkbox
        &:hover
            color: inherit

.simple-search-form
    max-width: 400px
    margin: 0 auto

.simple-search-submit
    width: 11rem
    max-width: 100%

.sticky-search
    position: sticky
    bottom: -1rem
    z-index: 100
    width: calc(100% + 2rem)
    background-color: $isaaffik-darkblue
    margin-left: -1rem
    padding: 1rem

.advanced-search__filters
    .radio, .checkbox
        &:hover
            color: inherit
