.is-wrapping
    flex-wrap: wrap

.is-space-between
    justify-content: space-between

.is-space-evenly
    justify-content: space-evenly

.is-not-wrapping-tablet
    flex-wrap: wrap
    +tablet
        flex-wrap: nowrap

.has-content-centered
    justify-content: center

.has-items-centered
    align-items: center
