.dialog
    +overlay
    z-index: 1000

html.has-dialog
    overflow: hidden

.modal-card-title
    padding-right: 0.5rem
    width: 100%
    +tablet
        width: 575px

.isav-dialog-trap-focus-node
    position: fixed
    left: 50%
    &.is-pre
        top: 0
    &.is-post
        bottom: 0
