.field
    display: block

.field.is-grouped-between
    justify-content: space-between

.field-label.field-label-tooltip
    flex-grow: 1.5

/* Hides controls on number inputs */
input[type="number"].has-hidden-number-controls
    /* Firefox */
    -moz-appearance: textfield
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0
