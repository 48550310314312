.uploader-zone
    position: relative
    width: 450px
    height: 200px
    border: dashed $isaaffik-grayish-white
    text-align: center

.uploader-image
    height: 100%
    width: auto

.uploader-remove-button
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    -ms-transform: translate(-50%, -50%)
    display: none

.uploader-content
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    -ms-transform: translate(-50%, -50%)

.uploader-zone:hover .uploader-remove-button,
.uploader-remove-button.is-shown
    display: inline-block
