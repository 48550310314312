@charset "utf-8"

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700;900&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap')
@import url('https://cdnjs.cloudflare.com/ajax/libs/vis/4.16.1/vis.css')
@import 'config'

@import "node_modules/bulma/bulma"

@import 'base/all'
@import 'elements/all'
@import 'components/all'
@import 'layout/all'
@import 'general/all'

.is-hidden-print
    @media print
        display: none !important
