.isav-select-optgroup
    padding: 0 0 0 0.5rem
    position: static !important

.isav-select-button
    @extend %input
    position: relative
    display: block
    width: 100%
    max-width: 100%
    text-align: left
    padding-right: 2.5rem
    border: 1px solid $isaaffik-blue

    &.is-disabled
        &, &:hover, &:focus, &:active
            cursor: not-allowed
            color: #757575
            border-color: #b5b5b5
            background: #ffffff

.isav-select-icon
    position: absolute
    top: 0.5rem
    right: 1rem
    margin: 0 !important

.isav-select-button-label-container
    position: relative
    display: block
    width: 100%
    height: 100%
    overflow: hidden

.isav-select-button-label
    position: absolute
    top: 0
    left: 0
    height: 100%
    display: block
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap


.isav-select-listbox
    position: absolute
    max-height: 260px
    overflow-x: hidden
    overflow-y: auto
    z-index: 1000
    @media screen and (min-height: 520px)
        max-height: 50vh
    &.is-short
        max-height: 180px
