a.dropdown-item
    &, &:hover
        text-decoration: none

.dropdown.is-active,
.buttons.has-addons .dropdown.is-active
    &, &:hover
        z-index: $zindex-dropdown

a.dropdown-item.is-activedescendant,
button.dropdown-item.is-activedescendant
    background-color: $dropdown-item-hover-background-color
    color: $dropdown-item-hover-color
    &.is-active
        background-color: darken($dropdown-item-active-background-color, 8%)
        color: $dropdown-item-active-color
