$page-padding: 1rem
$page-padding-desktop: 2rem
$page-padding-large: 4rem

=page-padding($large: false)
    padding-left: $page-padding
    padding-right: $page-padding

    +desktop
        @if ($large)
            padding-left: $page-padding-large
            padding-right: $page-padding-large
        @else
            padding-left: $page-padding-desktop
            padding-right: $page-padding-desktop
    @media print
        padding-left: 0
        padding-right: 0

%page-padding
    +page-padding

%page-padding-large
    +page-padding(true)
