img.is-covering
    object-fit: cover

img.is-containing
    object-fit: contain

img.is-positioned-top
    object-position: top

img.is-max-vh 
    max-height: 100vh
    +tablet
        // header + action-title + margins + margin-bottom(handpicked)
        // 164 + 50 + 32x2 + 10px = 288
        height: calc(100vh - 288px)
        max-height: 500px
