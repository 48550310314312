$white: #fff

$isaaffik-blue: #1b7faf
$isaaffik-lightblue: #2cb5f7
$isaaffik-darkblue: #064664
$isaaffik-gray: #A7A7A7
$isaaffik-grayish-white: #f2f2f2
$isaaffik-blueish-white: #ebf9ff
$isaaffik-green: #18c64d
$isaaffik-black: #333

$primary: $isaaffik-blue
$success: $isaaffik-green

$text: $isaaffik-black
$link: $isaaffik-lightblue
$link-hover: $isaaffik-lightblue

$isaaffik-note-background: #FAFFEB
$isaaffik-note-border: $isaaffik-lightblue

$radius: 7px
$radius-small: $radius
$radius-large: $radius

$family-sans-serif: 'Roboto', sans-serif
$family-primary: $family-sans-serif
$family-secondary: $family-sans-serif
$family-title: 'Roboto Slab', sans-serif

$size-1: 2.8125rem
$size-2: 2.8125rem
$size-3: 1.875rem
$size-4: 1.75rem
$size-5: 1.5625rem
$size-6: 1rem
$size-7: 0.75rem

$size-small: $size-7

$variable-columns: true

// overwrite tablet to be from 768px
$tablet: 768px

// ELEMENTS

$input-border-color: $isaaffik-blue

//table
$table-head-cell-color: $white
$table-head-background-color: $isaaffik-blue
$table-head-cell-border-width: 0
$table-foot-cell-color: $white
$table-foot-background-color: $isaaffik-blue
$table-foot-cell-border-width: 0
$table-striped-row-even-background-color: $isaaffik-blueish-white
$table-striped-row-even-hover-background-color: darken($isaaffik-blueish-white, 2)

//title
$title-color: $isaaffik-darkblue
$title-family: $family-title
$title-weight: 700

// COMPONENTS

$card-shadow: none
$card-header-shadow: none
$card-border-color: $isaaffik-lightblue
$card-footer-padding: 10px

$custom-colors: ('darkblue': ($isaaffik-darkblue, $white))

$footer-background-color: $isaaffik-darkblue
$footer-color: $white
$footer-padding: 3rem 1.5rem 6rem
$footer-padding-desktop: 3rem 4rem 6rem

$zindex-modal: 1100
$zindex-dropdown: 1000

$message-background-color: $white

@import 'node_modules/bulma/sass/utilities/all'
@import 'utilities/spacing'
@import 'utilities/shadow'
