table
    table-layout: fixed
    word-wrap: break-word

td.narrow-col, th.narrow-col
    width: 128px

th
    font-weight: 700

.table.has-actions tr
    cursor: pointer

.table.has-actions thead,
.table.has-actions tfoot
    tr
        cursor: initial

th.has-action,
td.has-action
    cursor: pointer

.table .no-data-row
    text-align: center

%table-reset
    display: block

$sizes: 30, 50, 70
.table.is-on-mobile-view
    &, td, th, tbody, thead, tfoot
        @extend %table-reset

    tr
        display: flex
        flex-wrap: wrap
        &:not(:last-child)
            border: $table-cell-border
            border-width: $table-cell-border-width

    th
        font-weight: 700
        width: 50%
        +tablet
            width: 30%

    td
        width: 50%
        +tablet
            width: 70%

    td, th
        border-bottom-width: 0

    @each $headerSize in $sizes
        $contentSize: 100 - $headerSize
        &.is-#{$headerSize}-#{$contentSize}
            th
                width: 1% * $headerSize

            td
                width: 1% + $contentSize

thead, tfoot
    th
        font-size: 14px
