.button
    @each $name, $values in $colors
        $color: nth($values, 1)
        $color-invert: nth($values, 2)
        &.is-#{$name}:focus
            background: bulmaDarken($color, 5%)

.button.is-icon
    background: transparent
    border: 0
    @each $name, $values in $colors
        $color: nth($values, 1)
        &.is-#{$name}
            color: $color
            &:hover,  &.is-hovered,
            &:active, &.is-active,
            &:focus,  &.is-focused
                color: bulmaDarken($color, 5%)
                background: transparent


.buttons.has-addons.is-flat,
.field.has-addons.is-flat
    .button
        &:first-child
            border-bottom-left-radius: 0
        &:last-child
            border-bottom-right-radius: 0

.buttons.has-addons.is-divided
    .button:not(:first-child)
        border-left-color: $white
    .button:not(:last-child):hover
        border-right-color: $white

.buttons.has-addons.is-vertical
    flex-direction: column
    align-items: stretch

    .button
        margin-right: 0
        margin-bottom: 0
        &:first-child
            border-radius: $radius $radius 0 0
        &:last-child
            border-radius: 0 0 $radius $radius

    &.is-flat .button:last-child
        border-radius: 0

    &.is-top-flat .button:first-child
        border-radius: 0

    .buttons-vertical-divider
        width: 100%
        height: 1px
        background: $white

.button .icon.is-tight.is-tight
    margin-left: calc(-0.5em - 1px)
    margin-right: calc(-0.5em - 1px)

a.button
    &, &:hover
        text-decoration: none

.buttons.has-small-padding .button,
.button.has-small-padding
    padding: 0 0.75em
    height: 1.8em

.button.is-transparent
    background: transparent
    border-color: transparent
    text-decoration: underline
    color: inherit

.button.is-linkstyle
    padding: 0
    height: auto
    border: none
    background: transparent
    &:hover, &:focus
        text-decoration: underline

.buttons.is-sticky
    +tablet
        position: sticky
        top: 0
        z-index: 1000
        padding: 0.5rem 0

.link-button
    background: transparent
    border: none
    text-decoration: none
    color: $link
    padding: 0
    margin: 0
    display: inline
    cursor: pointer
    &:hover,
    &:focus
        text-decoration: underline
