$caption-padding-vertical: $button-padding-vertical !default
$caption-padding-horizontal: $button-padding-horizontal !default

.caption
    padding: $caption-padding-vertical $caption-padding-horizontal
    border-radius: $radius
    border-bottom-left-radius: 0
    border-top-left-radius: 0
    font-weight: 500

    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    flex-wrap: wrap

@each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)
    .caption.is-#{$name}
        background: $color
        color: $color-invert
        @media print
            background: transparent
            color: $color

