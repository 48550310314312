a
    text-decoration: none
    &:hover
        text-decoration: underline

.content a
    text-decoration: underline

img.is-fullwidth
    width: 100%
