.isav-map-container
    display: block
    position: relative
    width: 400px
    max-width: 100%
    height: 300px
    &.is-in-fullscreen-mode
        z-index: $zindex-modal

    isav-advanced-search &
        width: 100%
        height: 90vh

    &.hidden
        display: none
        visibility: hidden

.isav-map-placeholder
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.isav-map
    width: 100%
    height: 100%
    display: block
    border: 1px solid $isaaffik-lightblue
    background: $isaaffik-blueish-white
    border-radius: 3px
    &.is-in-fullscreen-mode
        position: fixed
        top: 2rem
        left: 2rem
        width: calc(100% - 4rem)
        height: calc(100% - 4rem)

    .icon, .fas
        font-size: 14px

.isav-map-popup
    position: relative
    &__content
        background: $white
        border-radius: $radius
        padding: 0.5rem
        border: 1px solid $isaaffik-lightblue
        a
            display: block
            font-size: 14px
            line-height: 1.2

.isav-map-icon
    font-size: 14px

.isav-map-type-select
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    gap: 0.5rem
