.card
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    border: 1px solid $card-border-color
    border-radius: $radius
    &.is-full
        width: 100%
        height: 100%

%card-base
    border-color: $card-border-color
    border-style: solid
    border-width: 0
    margin: 0

.card-image img
    @extend %card-base

.card-header,
.card-content
    @extend %card-base
    padding: 0 1rem
    margin: 0.75rem 0 1.5rem

.card-header a
    color: $isaaffik-darkblue
    &:hover
        color: $isaaffik-blue

.card-footer
    @extend %card-base
    padding: $card-footer-padding
    margin-top: auto

.card-footer-content
    font-size: $small-font-size
    padding: 1em
    background: $isaaffik-lightblue
    color: $white
    width: 100%
    strong
        color: inherit
    a
        color: inherit
        text-decoration: underline

.card-image.has-caption .caption
    position: absolute
    bottom: 0
    left: 0
