.page-container
    @extend %page-padding

.page-container-vertical
    margin-top: 2rem
    margin-bottom: 2rem

.page-content
    display: block
    padding: $page-padding
    max-width: 100%
    +desktop
        padding: $page-padding-desktop
        max-width: 932px
        max-width: calc(#{$page-padding-desktop} + 900px)
        margin-left: auto
        margin-right: auto

.content-type-form
    $field-index-width: 230px
    $content-type-form-width: 900px
    $content-type-form-padding: 16px
    $min-width: $field-index-width + $content-type-form-width + 2 * $content-type-form-padding
    $max-space: $content-type-form-width + 2 * $content-type-form-padding + 2 * $field-index-width

    padding: $content-type-form-padding
    width: 100%
    +desktop
        width: calc(100vw - #{$field-index-width})
    @media (min-width: #{$min-width})
        width: $content-type-form-width
        margin: 0 calc((100% - #{$min-width}) / 2)
    @media (min-width: #{$max-space})
        margin: 0 auto
    .field-index
        width: $field-index-width

    .field-label label
        font-weight: 700
