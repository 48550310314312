.footer
    +desktop
        padding: $footer-padding-desktop

    .title, strong
        color: inherit

.footer-title
    color: $white
    font-weight: 700
    font-size: 18px
    margin-bottom: 1.5rem
    @each $size in $sizes
        $num: index($sizes, $size)
        &.is-#{$num}
            font-size: $size
